<template>
  <Row class="page5">

    <Col style="width:50%;padding:0 1%;">
      <div class="description">
        <span class='title'><span class="title-text">{{picsTitle}}</span></span>
        <span class="angle1"></span>
        <span class="angle2"></span>
        <span class="angle3"></span>
        <span class="angle4"></span>
        <div class="describeList">
          <span v-if="describeList[chooseIndex]">{{describeList[chooseIndex]}}</span>
          <span v-else style="text-align:center;font-size: 18px;">暂无简介......</span>
        </div>
      </div>
      <div class="left">
        <china-map ref="chinaMap" :resdata="resdata"></china-map>
      </div>
    </Col>
    <Col style="width:50%;height: 95%;display: flex;flex-direction: column">
     <div style="width: 100%;height: 50%;display: flex;">
       <div class="top-left-list">
         <span class='title'><span class="title-text">{{picsTitle}}</span></span>
         <span class="angle1"></span>
         <span class="angle2"></span>
         <span class="angle3"></span>
         <span class="angle4"></span>
         <area-chart style="height: 55%" ref="chart14" :config="configData9" :choiceIndex="5"></area-chart>
         <colors-bar ref="chart15"></colors-bar>
       </div>
       <div class="top-right-list">
         <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
         <span class="angle1"></span>
         <span class="angle2"></span>
         <span class="angle3"></span>
         <span class="angle4"></span>
         <funnel ref="chart16"></funnel>
       </div>
     </div>
      <div class="center-bottom" style="display:flex;">
          <div class="bottom-left">
            <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
            <span class="angle1"></span>
            <span class="angle2"></span>
            <span class="angle3"></span>
            <span class="angle4"></span>
            <workPics ref="workPic" :isBig="true"></workPics>
          </div>
        <div class="bottom-right" style="margin-left: 2%">
          <span class='title'><span class="title-text">荣誉图片</span></span>
          <span class="angle1"></span>
          <span class="angle2"></span>
          <span class="angle3"></span>
          <span class="angle4"></span>
          <honorPics ref="honor" :isLarge="true"></honorPics>
        </div>
      </div>
    </Col>
    <div class="tabNav">
      <div v-for="(item,index) in workName" :key="index" @click="chooseNav(item,index)" :class="index==chooseIndex?'on_workname':'workname'">{{item}}</div>
    </div>
  </Row>
</template>

<script>
const chinaMap  = () => import('./components/chinaMap');
const areaChart = ()=> import('./components/areaChart');
const funnel = () => import('./components/page1/funnel');
const colorsBar = ()=> import('./components/page6/colorsBar');
const workPics = () => import('./components/page2/workPictures');
const honorPics = ()=> import('./components/page2/HonoraryPics.vue');

export default {
  name: 'page6',
  components: {
    chinaMap,
    funnel,
    areaChart,
    colorsBar,
    workPics,//项目图片
    honorPics, //荣誉图片
  },
  data() {
    return {
      //tabNav子菜单
      workName:['巷子花开','"雨润花开"情系溆浦"','三湘学子圆梦计划',],
      chooseIndex:0,
      picsTitle:'', //右下角图片标题
      describeList:[
          '“巷子花开”乡村振兴项目是由宁乡市委统战部、宁乡市民政局、巷子口镇人民政府、长沙市雨花区群英公益发展促进中心在巷子口镇联合打造社会力量参与乡村振兴的示范性项目。项目以“一个平台，三个基地”，即“巷子花开”同心汇公益服务平台、“巷子花开”公益实践基地、“巷子花开”志愿者素质提升基地、“巷子花开”新的社会阶层人士实践创新基地。充分发挥广泛汇聚社会资源优势，持续探索“公益为本”的乡村振兴新路径。用“社工+志愿者”的模式，通过“公益项目”引导村民以志愿者身份参与乡村治理，形成助人-自助-互助-自治的乡村振兴内循环体系，同时坚持引进来与走出去相结合，链接资源，开辟政府、社会组织、爱心企业、村民多方参与的“巷子花开”共建共治共享的乡村振兴发展模式。',
          '为搭建雨花区对口帮扶溆浦县关爱困境儿童的公益网络，提升家庭及社会各界儿童保护和儿童权利的理念和意识，落实《雨花区乡村振兴对口帮扶溆浦县的实施方案》要求，广泛动员社会力量参与，更好地形成社会合力，探索并完善溆浦儿童保护制度与体系，回应溆浦困境儿童需求，助力溆浦乡村振兴，在雨花区委、雨花区政府、溆浦县委、溆浦县政府指导下，群英会发起“雨润花开·情系溆浦”溆浦县困境儿童项目。\n' +
          '项目联动上海联劝公益基金会，通过建档评估、物资帮扶、 走访陪伴、 个案服务、 团体活动、 家庭监护支持、 政社联动活动、公众参与活动等专业手法，为溆浦县350名因家庭监护缺失或监护不当的儿童构建社会支持网络，帮助困境儿童减轻和摆脱困境，保障困境儿童的基本权利，让困境儿童健康快乐成长、平等发展。',
          '在湖南省教育基金会指导下，群英会携手湖南三湘银行发起--伴你上大学·“三湘学子”圆梦计划，为品学兼优的大学生搭建成长与帮扶平台。通过开展学业资助、成长互助、就业交流等活动，让他们不会因经济问题终止大学梦，并获得更多锻炼和成长机会，助力实现个人梦想与社会价值。同时，以助学为起点，搭建平台为宗旨，希望通过三湘伙伴计划把每一届三湘学子联结起来，让每一位“三湘学子”成为“三湘学子”圆梦计划的志愿者，参与项目的共建活动，让公益成为传承的支点。'
        ],
      //地图展示项目数据
      resdata:[
        {
          name: '长沙市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '730',
              serviceCount: '100000',
              volunteerCount: '350',
              schoolCount: '8',
              money: '155',
              attent: '10',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'株洲市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'湘潭市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'衡阳市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'常德市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'益阳市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'娄底市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'郴州市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'永州市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'怀化市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '40',
              serviceCount: '350',
              volunteerCount: '200',
              schoolCount: '39',
              money: '105',
              attent: '3',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'湘西土家族苗族自治州',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'张家界市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'岳阳市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
        {
          name:'邵阳市',
          value:1,
          choiceIndex:1,
          list:[
            {
              title: '巷子花开',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '“雨润花开”情系溆浦',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
            {
              title: '龙山乡村振兴',
              soprtcount: '0',
              serviceCount: '0',
              volunteerCount: '0',
              schoolCount: '0',
              money: '0',
              attent: '0',
            },
          ]
        },
      ],
      //项目图片数组
      workPicsList:[
        {
          name:'“巷子花开”乡村振兴项目照片',
          picsList:[
            require('@/assets/ruralRevitalization/flowerXiangZi/1.png'),
            require('@/assets/ruralRevitalization/flowerXiangZi/2.png'),
            require('@/assets/ruralRevitalization/flowerXiangZi/3.jpg'),
            require('@/assets/ruralRevitalization/flowerXiangZi/4.jpg'),
            require('@/assets/ruralRevitalization/flowerXiangZi/5.jpg'),
            require('@/assets/ruralRevitalization/flowerXiangZi/6.jpg'),
            require('@/assets/ruralRevitalization/flowerXiangZi/7.jpg'),
            require('@/assets/ruralRevitalization/flowerXiangZi/8.jpg'),
            require('@/assets/ruralRevitalization/flowerXiangZi/9.jpg'),
            require('@/assets/ruralRevitalization/flowerXiangZi/10.jpg'),
            require('@/assets/ruralRevitalization/flowerXiangZi/11.png'),
            require('@/assets/ruralRevitalization/flowerXiangZi/12.png'),
            require('@/assets/ruralRevitalization/flowerXiangZi/13.jpg'),
          ]
        },
        {
          name:'“雨润花开 情系溆浦”困境儿童公益项目',
          picsList:[
            require('@/assets/ruralRevitalization/RainMoistFlower-children/1.jpg'),
            require('@/assets/ruralRevitalization/RainMoistFlower-children/2.jpg'),
            require('@/assets/ruralRevitalization/RainMoistFlower-children/3.jpg'),
            require('@/assets/ruralRevitalization/RainMoistFlower-children/4.jpg'),
            require('@/assets/ruralRevitalization/RainMoistFlower-children/5.jpg'),
            require('@/assets/ruralRevitalization/RainMoistFlower-children/6.jpg'),
            require('@/assets/ruralRevitalization/RainMoistFlower-children/7.jpg'),
            require('@/assets/ruralRevitalization/RainMoistFlower-children/8.jpg'),
            require('@/assets/ruralRevitalization/RainMoistFlower-children/9.jpg'),
            require('@/assets/ruralRevitalization/RainMoistFlower-children/10.jpg'),
          ]
        },
        {
          name:'三湘学子圆梦计划',
          picsList:[
            require('@/assets/ruralRevitalization/threeXiangStudent/1.jpg'),
            require('@/assets/ruralRevitalization/threeXiangStudent/2.jpg'),
            require('@/assets/ruralRevitalization/threeXiangStudent/3.jpg'),
            require('@/assets/ruralRevitalization/threeXiangStudent/4.jpg'),
            require('@/assets/ruralRevitalization/threeXiangStudent/5.jpg'),
          ]
        },
      ],
      //右上角聊天数据
      chatRadarData: {
        title: '各部门聊天对比',
        position: ['center', '85%'],
        center: ['50%', '50%'],
        indicator: [
          {text: '一部'},
          {text: '二部'},
          {text: '三部'},
          {text: '四部'},
          {text: '五部'},
          {text: '六部'}
        ],
        data: [
          {
            name: '聊天次数',
            color: '#0DF5F8',
            value: [100, 8, 0.40, -80, 2000, 332],
          },
          {
            name: '聊天人数',
            color: '#7921AD',
            value: [60, 5, 0.30, -100, 1500, 221],
          }
        ]
      },
      cnfigData1: {
        color: '#5CB1C1',
        name: ['（次）', '（人）'],
        data: [
          {
            name: '聊天次数',
            color: ['#9e70ff', '#6e5eff'],
            data: [200, 12, 21, 54, 260, 130, 210],
          },
          {
            name: '聊天人数',
            color: ['#48cefd', '#5356f1'],
            data: [50, 182, 234, 191, 190, 30, 10],
          }
        ]
      },
      // 交友分析面积图
      configData9: {
        title: '【项目数据】',
        color: '#75deef',
        name: ['（个）', '（个）'],
        data: [
          {
            name: '活动场次',
            color: ['#feed2c', '#353103'],
            data: []
          },
          {
            name: '社会组织参与数',
            color: ['#2871ea', '#0a1b41'],
            data: []
          },
          {
            name: '志愿者人数',
            color: ['#935adf', '#230f3e'],
            data: []
          },
          {
            name: '覆盖学校数量',
            color: ['#e65f2d', '#551f0b'],
            data: []
          }
        ]
      },
      // 交友方式柱图
      // colorsData: [
      //   {
      //     itemStyle: {
      //       color: "#2c7bfe",
      //
      //     },
      //     name: '长沙市',
      //     value: 155
      //   },
      //   {
      //     itemStyle: {
      //       color: "#c2232a",
      //     },
      //     name: '株洲市',
      //     value: 0
      //   },
      //   {
      //     itemStyle: {
      //       color: "#feed2c",
      //
      //     },
      //     name: '湘潭市',
      //     value: 0
      //   },
      //   {
      //     itemStyle: {
      //       color: "#a262f2",
      //
      //     },
      //     name: '衡阳市',
      //     value: 0
      //   },
      //   {
      //     itemStyle: {
      //       color: "#62d5f2",
      //     },
      //     name: '常德市',
      //     value:0
      //   },
      //   {
      //     itemStyle: {
      //       color: "#fe672c",
      //     },
      //     name: '娄底市',
      //     value: 0
      //   },
      //   {
      //     itemStyle: {
      //       color: "#69f262",
      //     },
      //     name: '郴州市',
      //     value: 0
      //   },
      //   {
      //     itemStyle: {
      //       color: "#2ca8fe",
      //     },
      //     name: '永州市',
      //     value: 0
      //   },
      // ],

      pieData1: {// 饼图数据1
        title: "TOP数据1",
        color: '#2C7BFE',

        data: [
          {
            value: 60,
            name: '分类1',
            itemStyle: {
              color: '#1456FE'
            }

          },
          {
            value: 20,
            name: '分类2',
            itemStyle: {
              color: '#00CCFF'
            }
          },
          {
            value: 80,
            name: '分类3',
            itemStyle: {
              color: '#142AFE'
            }
          },
          {
            value: 40,
            name: '分类4',
            itemStyle: {
              color: '#1493FE'
            }
          },
          {
            value: 40,
            name: '分类5',
            itemStyle: {
              color: '#252448'
            }
          }
        ],
      },
      pieData2: {// 饼图数据1
        title: "TOP数据2",
        color: '#2C7BFE',

        data: [
          {
            value: 60,
            name: '分类1',
            itemStyle: {
              color: '#142AFE'
            }

          },
          {
            value: 20,
            name: '分类2',
            itemStyle: {
              color: '#1493FE'
            }
          },
          {
            value: 80,
            name: '分类3',
            itemStyle: {
              color: '#252448'
            }
          },
          {
            value: 40,
            name: '分类4',
            itemStyle: {
              color: '#00CCFF'
            }
          },
          {
            value: 40,
            name: '分类5',
            itemStyle: {
              color: '#1456FE'
            }
          }
        ],
      },
      pieData3: {// 饼图数据1
        title: "TOP数据3",
        color: '#2C7BFE',

        data: [
          {
            value: 60,
            name: '分类1',
            itemStyle: {
              color: '#1493FE'
            }

          },
          {
            value: 20,
            name: '分类2',
            itemStyle: {
              color: '#142AFE'
            }
          },
          {
            value: 80,
            name: '分类3',
            itemStyle: {
              color: '#1456FE'
            }
          },
          {
            value: 40,
            name: '分类4',
            itemStyle: {
              color: '#00CCFF'
            }
          },
          {
            value: 40,
            name: '分类5',
            itemStyle: {
              color: '#252448'
            }
          }
        ],
      },
      resizeFn: null
    }
  },
  methods:{
    //  点击子菜单选择
    chooseNav(item,index){
      this.chooseIndex = index;
      this.picsTitle = item; //标题随着nav导航选项而变化
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = this.workPicsList[index].picsList;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角图片重新渲染滑动
      this.$refs.workPic.loading=false; //加载中
      // 荣誉图片
      // this.$refs.honor.honoraryPicsList = this.workPicsList[index].picsList;
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load=false; //加载中
      //选择导航栏 把index对应传给value 从而获取不同数据
      for (var i=0;i<this.resdata.length;i++){
        this.resdata[i].choiceIndex=index;

        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
        let totalValue = Number(this.resdata[i].list[index].attent) + Number(this.resdata[i].list[index].money)
            + Number(this.resdata[i].list[index].schoolCount) + Number(this.resdata[i].list[index].serviceCount)
            + Number(this.resdata[i].list[index].soprtcount) + Number(this.resdata[i].list[index].volunteerCount)
        this.resdata[i].value = totalValue;
      }

      this.$refs.chinaMap.seriesData = this.resdata
      this.$refs.chinaMap.setMap(); //刷新地图数据
      //每次点击导航选项将之前数据置空 再进行下面操作
      this.$refs.chart15.moneyList = [];
      this.$refs.chart14.config.data[0].data = [];
      this.$refs.chart14.config.data[1].data = [];
      this.$refs.chart14.config.data[2].data = [];
      this.$refs.chart14.config.data[3].data = [];
      this.$refs.chart16.serviceList=[];

      //各城市的数据遍历获取
      this.resdata.forEach(it=>{
        this.$refs.chart14.config.data[0].data.push(it.list[index].soprtcount) //活动场次
        this.$refs.chart14.config.data[1].data.push(it.list[index].attent) //社会组织参与数
        this.$refs.chart14.config.data[2].data.push(it.list[index].volunteerCount) //志愿者人数
        this.$refs.chart14.config.data[3].data.push(it.list[index].schoolCount) //覆盖学校数量
        this.$refs.chart15.moneyList.push(it.list[index].money) //投入款物
        this.$refs.chart16.serviceList.push(it.list[index].serviceCount) //右边服务人次
      })
      //刷新子组件数据
      this.$refs.chart15.setChart();
      this.$refs.chart14.setChart();
      this.$refs.chart16.setChart();
    },
  },
  mounted() {
    setTimeout(()=>{
      this.chooseNav(this.workName[0],0);
    },100)
    this.resizeFn = this.$debounce(()=> {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      // this.$refs.channelBar1.setChart();
      // this.$refs.distributionSolider1.setChart();
      // this.$refs.channelBar2.setChart();
      // this.$refs.distributionSolider2.setChart();
      // //this.$refs.pies.setPies();
      // this.$refs.redPocket.setPocket();
      // this.$refs.webcastsRisk.setWebcasts();
      // this.$refs.deviceSafeRisk.setDeviceSafe();
      // this.$refs.ring1.drawRing();
      // this.$refs.ring2.drawRing();
      // this.$refs.ring3.drawRing();
      // for (let i = 1; i < 9; i++) {
      //   this.$refs['chart' + i].setChart()
      //
      // }
      this.$refs.chinaMap.setMap();
      // this.$refs.hnPronvice.mapEchartsInit();
      // this.$refs.hotWords.setChart();

    }, 500)
    window.addEventListener('resize', this.resizeFn)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeFn)
  }
}
</script>

<style lang="less" scoped>
.page5 {
  height: 98%;
  width: 100%;
  padding: 8px 14px 12px 0;
  //padding: 8px 10px 18px;
  background: #03044A;
  overflow: hidden;
  position: relative;
  .tabNav{
    width: auto;
    margin: 0 auto;
    height: 4.5%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.5%;
    left: 42%;
    .workname{
      color:#fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right:1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }
    .on_workname{
      color:#4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 35px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }
  .ivu-col {
    height: 100%;
    float: left;
  }
  .top-right-list{
    margin-left: 2%;
  }

  .top-left-list,.top-right-list,.left,.description,.bottom-right,.bottom-left{
    border: 1px solid #0D2451;
    position: relative;
    background: #151456;
    //background:rgb(8,25,79);
    box-sizing: border-box;
    height: 95%;
    width:100%;

    .title {
      position: absolute;
      display: inline-block;
      color: #6EDDF1;
      border: 2px solid #6EDDF1;
      height: 18px;
      padding: 0 2px;
      left: 50%;
      transform: translate(-50%, -50%);

      .title-text {
        position: relative;
        font-size: 16px;
        background: #09102E;
        display: inline-block;
        padding: 0 4px;
        white-space: nowrap;
        transform: translateY(-5px);
        font-family: "宋体", "Times New Roman", Times, serif;
      }
    }
  }
  .left{
    height: 72%!important;
    background: none!important;
    border: none!important;
  }
  .description{
    background: none!important;
    height: 25%!important;
    box-sizing: border-box;
    padding-bottom: 2%;
    margin-bottom: -1%;
    //width: 105%!important;;
  }
  .describeList{
    text-indent: 2%;
    display: flex;
    height: 96%;
    width: 98%;
    margin: 2% auto;
    align-items: center;
    color:rgb(167,198,235);
    line-height: 27px;
    font-size: 15px;
    //overflow-y: scroll;
    font-family: "华文细黑", Courier New, Courier, monospace;
    span{
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
  .describeList::-webkit-scrollbar {/*滚动条整体样式*/
    width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .describeList::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #a1b3dc;
  }
  .describeList::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: rgb(8,25,79);
  }
  .center-bottom {
    width: 100%!important;
    height: 48%!important;
    margin-top: 2%;

    .bottom-left,.bottom-right {
      width: 50%;
      height: 100%;
      position: relative;
      #left1, #left2, #left3, #right1, #right2, #right3, #center2 {
        height: 100%;
      }

      .chart-68 {
        width: 68%;
        height: 100%;
        float: left;
      }

      .chart-32 {
        width: 32%;
        height: 100%;
        float: left;
      }
    }
  }
  }

</style>
